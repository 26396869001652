import { ReactComponent as KeyboardIcon } from 'assets/images/Keyboard-icon.svg';
import { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  Box,
  Button,
  Grow,
  LinearProgress,
  Typography,
  alpha
} from '@mui/material';

import { Colors } from '../../../design/theme';
import useIsLargeScreen from '../../../hooks/useIsLargeScreen';
import { chatSelector } from '../../../redux/chat/chat.selector';
import {
  addMessage,
  resetSuggestedAction,
  setIsRecording,
  setMode
} from '../../../redux/chat/chat.slice';
import { getSocket } from '../../../services/socket';
import { ChatEvent } from '../enums/ChatEvent.enum';
import { MessageError } from '../enums/MessageError.enum';
import { MessageSender } from '../enums/MessageSender.enum';

const ChatInputVoice = ({ disabled }: { disabled: boolean }) => {
  const isLargeScreen = useIsLargeScreen();
  const dispatch = useDispatch();

  const [mediaRecorder, setMediaRecorder] = useState<MediaRecorder | null>(
    null
  );
  const { isRecording } = useSelector(chatSelector);
  const [progressBar, setProgressBar] = useState(0);
  const progressRef = useRef<number>(0);
  const socket = getSocket();

  useEffect(() => {
    if (isRecording) {
      progressRef.current = 0;
      setProgressBar(0);

      const timer = setInterval(() => {
        progressRef.current += 1; // speed of progress bar
        if (progressRef.current >= 100) {
          handleStopRecording();
          clearInterval(timer);
        } else {
          setProgressBar(progressRef.current);
        }
      }, 100);

      return () => {
        clearInterval(timer);
        setProgressBar(0);
      };
    }

    if (!isRecording && mediaRecorder) {
      setProgressBar(0);
      mediaRecorder.stop();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isRecording]);

  const handleEmitError = () => {
    dispatch(
      addMessage({
        id: `error-${Date.now()}`,
        error: MessageError.USER_MESSAGE_ERROR,
        sender: MessageSender.AI,
        text: 'Sorry, there was a problem while recording.'
      })
    );
  };

  const handleStartRecording = async () => {
    try {
      if (isRecording) return handleStopRecording();
      dispatch(resetSuggestedAction());

      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });

      if (!window.MediaRecorder)
        throw new Error('MediaRecorder is not supported in this browser');

      const options: MediaRecorderOptions = {};

      if (MediaRecorder.isTypeSupported('audio/webm')) {
        options.mimeType = 'audio/webm';
      } else if (MediaRecorder.isTypeSupported('audio/aac')) {
        options.mimeType = 'audio/aac';
      } else if (MediaRecorder.isTypeSupported('audio/mp4')) {
        options.mimeType = 'audio/mp4';
      }

      const recorder = new MediaRecorder(stream, options);
      setMediaRecorder(recorder);

      recorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          socket.emit(ChatEvent.USER_AUDIO_MESSAGE, event.data);
        }
      };

      recorder.start(1000);
      dispatch(setIsRecording(true));
    } catch (error) {
      handleEmitError();
      if (error instanceof Error) {
        if (error.name === 'NotAllowedError') {
          dispatch(
            addMessage({
              id: `error-${Date.now()}`,
              error: MessageError.USER_MESSAGE_ERROR,
              sender: MessageSender.AI,
              text: 'Microphone access was denied. Please check your browser settings.'
            })
          );
        } else if (error.name === 'NotFoundError') {
          dispatch(
            addMessage({
              id: `error-${Date.now()}`,
              error: MessageError.USER_MESSAGE_ERROR,
              sender: MessageSender.AI,
              text: 'No microphone was found. Please ensure your device has a working microphone.'
            })
          );
        }
      }
    }
  };

  const handleStopRecording = () => {
    if (mediaRecorder) {
      try {
        if (mediaRecorder.state === 'recording') {
          mediaRecorder.stop();
        }

        if (mediaRecorder.stream) {
          mediaRecorder.stream.getTracks().forEach((track) => {
            track.stop();
            track.enabled = false;
          });
        }

        setMediaRecorder(null);
        mediaRecorder.ondataavailable = null;
      } catch (error) {
        console.error('Error stopping recording:', error);
      }
    }

    if (isRecording) {
      socket.emit(ChatEvent.USER_AUDIO_MESSAGE, undefined);
    }

    dispatch(setIsRecording(false));
  };

  const handleSwitchMode = () => {
    if (mediaRecorder) {
      mediaRecorder.stop();
      mediaRecorder.stream.getTracks().forEach((track) => track.stop());
      setMediaRecorder(null);
      mediaRecorder.ondataavailable = null;
    }
    if (isRecording) {
      dispatch(setIsRecording(!isRecording));
      socket.emit(ChatEvent.USER_AUDIO_MESSAGE, 'cancel');
    }

    dispatch(setMode('text'));
  };

  return (
    <Box>
      <Box
        sx={{
          width: '100%',
          minHeight: isRecording ? '4.5rem' : '2rem',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'end',
          alignItems: 'center',
          borderTop:
            isLargeScreen || isRecording
              ? `1px solid ${Colors.lightGrey}`
              : 'none'
        }}>
        {isRecording && (
          <Grow in={isRecording} timeout={250}>
            <Typography
              sx={{
                color: Colors.green.dark,
                fontSize: isLargeScreen ? '1.125rem' : '1rem',
                fontFamily: 'Nunito',
                fontWeight: 600,
                textAlign: 'center',
                paddingBottom: '1.125rem',
                paddingX: '2.5rem'
              }}>
              Try describing a recent event or setting a goal
            </Typography>
          </Grow>
        )}
      </Box>

      <Box
        sx={{
          width: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}>
        <Box
          sx={{
            width: '100%',
            maxWidth: '25rem',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            gap: '1rem'
          }}>
          <Button
            type="button"
            fullWidth
            disabled={disabled}
            onClick={handleStartRecording}
            sx={{
              fontSize: !isLargeScreen ? '1rem' : '1.125rem',
              boxShadow: `0px 1px 1px 1px ${alpha(Colors.primary.dark, 0.1)}`,
              borderRadius: '3rem',
              background: isRecording ? Colors.green.main : 'white',
              border: 'none',
              color: isRecording ? Colors.white : Colors.neutralGrey,
              fontFamily: 'Nunito',
              fontWeight: 500,
              height: isLargeScreen ? '5.25rem' : '3.75rem',
              position: 'relative',
              overflow: 'hidden',
              paddingLeft: isRecording ? '0.75rem' : '1rem',
              maxWidth: isLargeScreen ? '100%' : '10.25rem',
              '& .MuiOutlinedInput-notchedOutline': {
                border: 'none'
              },
              '&:active': {
                backgroundColor: isRecording ? Colors.green.main : 'white',
                boxShadow: `0px 1px 1px 1px ${alpha(Colors.primary.dark, 0.1)}`
              },
              '&:hover': {
                backgroundColor: isRecording ? Colors.green.main : 'white',
                boxShadow: `0px 1px 1px 1px ${alpha(Colors.primary.dark, 0.1)}`
              },
              cursor: 'pointer'
            }}>
            {isRecording ? (
              <Typography
                sx={{
                  display: 'flex',
                  alignItems: 'center',
                  fontFamily: 'Nunito',
                  fontWeight: 600,
                  gap: '0.75rem',
                  zIndex: 40,
                  fontSize: !isLargeScreen ? '1rem' : '1.125rem'
                }}>
                Listening
              </Typography>
            ) : (
              'Tap to speak'
            )}
            {isRecording && (
              <LinearProgress
                variant="determinate"
                value={progressBar}
                sx={{
                  position: 'absolute',
                  left: 0,
                  top: 0,
                  bottom: 0,
                  width: '110%',
                  height: '100%',
                  backgroundColor: Colors.green.main,
                  '& .MuiLinearProgress-bar': {
                    backgroundColor: Colors.green.medium
                  },
                  zIndex: 1
                }}
              />
            )}
          </Button>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              background: 'rgba(0, 0, 0, 0.1)',
              borderRadius: '50%',
              padding: isLargeScreen ? '1rem' : 0,
              zIndex: 30
            }}>
            <Button
              onClick={handleSwitchMode}
              sx={{
                height: '3.375rem',
                width: '3.375rem',
                minWidth: '3.375rem',
                background: Colors.primary.dark,
                padding: 0,
                borderRadius: '50%',
                '&:hover': {
                  background: Colors.green.main
                }
              }}>
              <KeyboardIcon
                style={{ width: '1.5rem', height: '1.5rem', padding: 0 }}
              />
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ChatInputVoice;
