import affirmationsPrev from 'assets/images/hub/affirmations-prev.png';
import meditationsPrev from 'assets/images/hub/guided-meditation.png';
import avatarPrev from 'assets/images/hub/listening-headphones.png';
import visualizationsPrev from 'assets/images/hub/visualization-prev.png';
import { useAppSelector } from 'hooks/useAppSelector';
import useIsLargeScreen from 'hooks/useIsLargeScreen';
import React from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Button, Typography, alpha, useMediaQuery } from '@mui/material';

import theme, { Colors } from 'design/theme';

import { userSelector } from 'redux/user/user.selector';

import { useAppDispatch } from '../../../hooks/useAppDispatch';
import { resetAffirmationState } from '../../../redux/affirmation/afirmation.slice';
import {
  cleanMeditation,
  setActualStepMeditation
} from '../../../redux/meditations/meditation.slice';
import { getOutcomesVisualization } from '../../../redux/visualization/visualization.action';
import { cleanVisualization } from '../../../redux/visualization/visualization.slice';
import RoutesEnum from '../../../types/routes.enum';
import { User } from '../../../types/user.entity';
import { newAffirmationUISelector } from '../../new-affirmation/redux/ui/ui.selector';
import {
  cleanCustomFlow,
  initialStateNewAffirmationUiState,
  setActualStep,
  setCustomFlow,
  setHomeStatus,
  setLastStep
} from '../../new-affirmation/redux/ui/ui.slice';
import { Product } from '../enums/product.enum';
import { ProductOption } from '../interfaces/product-option.interface';

const catalogProducts: ProductOption[] = [
  {
    product: Product.AVATAR,
    title: 'Get a suggestion',
    description: 'Set your intentions and let AI suggest the best tool',
    tip: 'Ideal for personal exploration',
    image: avatarPrev,
    time: 3,
    isReady: true
  }
];

export const CatalogHome: React.FC = () => {
  const isLargeScreen = useIsLargeScreen();
  const userInfo = useAppSelector(userSelector).ui.userInfo;
  const isSmallScreen = useMediaQuery(theme.breakpoints.up(430));
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { wantWorkOn } = useAppSelector(newAffirmationUISelector);

  const checkUserAccess = (
    user: Partial<User> | undefined,
    isProductReady: boolean
  ): boolean => {
    if (!user) return false;

    return isProductReady;
  };

  const handleClick = (selectedOption: Product) => {
    switch (selectedOption) {
      case Product.AFFIRMATIONS:
        dispatch(resetAffirmationState());
        dispatch(
          setLastStep(
            initialStateNewAffirmationUiState.lastStep + wantWorkOn.length
          )
        );
        dispatch(setCustomFlow(false));
        dispatch(setHomeStatus(false));
        dispatch(cleanCustomFlow());
        dispatch(setActualStep(1));
        navigate(RoutesEnum.NEW_AFFIRMATION);
        break;
      case Product.VISUALIZATIONS:
        dispatch(cleanVisualization());
        dispatch(getOutcomesVisualization());
        navigate(RoutesEnum.VISUALIZATION);
        break;
      case Product.MEDITATIONS:
        dispatch(cleanMeditation());
        dispatch(setActualStepMeditation(1));
        navigate(RoutesEnum.NEW_MEDITATION);
        break;
      case Product.AVATAR:
        navigate(RoutesEnum.AVATAR);
        break;
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: '1rem' }}>
      {catalogProducts.map((option) => (
        <Button
          data-testid="productOption"
          key={option.product}
          sx={{
            padding: 0,
            overflow: 'hidden',
            height: isLargeScreen ? '13.5rem' : '16.5rem',
            width: '100%',
            maxWidth: { md: '17rem' },
            background: Colors.white,
            borderRadius: '0.5rem',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            color: Colors.green.main,
            '&:hover': {
              background: Colors.green.light,
              color: Colors.green.dark
            },
            '&:active': {
              boxShadow: 'inset 0px 2px 0px 0px rgba(255, 255, 255, 0)',
              background: Colors.green.light,
              color: Colors.green.dark
            },
            '&.Mui-disabled': {
              background: Colors.grey,
              color: Colors.grey,
              opacity: 0.5
            },
            '&.Mui-disabled:hover': {
              cursor: 'not-allowed'
            },
            boxShadow: `0px 2px 1px -1px ${alpha(Colors.primary.dark, 0.5)}`
          }}
          onClick={() => handleClick(option.product)}
          disabled={!checkUserAccess(userInfo, option.isReady)}>
          <Box
            sx={{
              width: '100%',
              height: isLargeScreen ? '50%' : '60%'
            }}>
            <Box
              component="img"
              src={option.image}
              alt={option.title}
              sx={{
                height: '100%',
                width: '100%',
                objectFit: 'cover'
              }}
            />
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: isSmallScreen ? 'flex-start' : 'center',
              alignItems: 'start',
              height: '50%',
              paddingY: '1rem',
              width: '100%',

              paddingX: '1rem',
              textAlign: 'left'
            }}>
            <Typography
              sx={{
                fontFamily: 'Nunito',
                fontSize: '1rem',
                fontWeight: 500,
                color: Colors.black
              }}>
              {option.title}
            </Typography>

            <Typography
              sx={{
                fontFamily: 'Nunito',
                fontSize: '0.875rem',
                fontWeight: 500,
                color: Colors.lightGreyGreen
              }}>
              {option.description}
            </Typography>
          </Box>
        </Button>
      ))}
    </Box>
  );
};
