import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, Grid, Typography } from '@mui/material';

import { TrendingButton } from '../../../../components/TrendingButton';
import { Colors } from '../../../../design/theme';
import { useAppDispatch } from '../../../../hooks/useAppDispatch';
import { useAppSelector } from '../../../../hooks/useAppSelector';
import {
  getPublishedAffirmationById,
  getTrendingAffirmations
} from '../../../../redux/affirmation-templates/templates.action';
import { affirmationsTemplate } from '../../../../redux/affirmation-templates/templates.selector';
import { generateTemplate } from '../../../../redux/affirmation/affirmation.action';
import {
  setSelectedOutcome,
  setSelectedTemplate
} from '../../../../redux/affirmation/afirmation.slice';
import { TopItemDto } from '../../../../services/affirmation-templates';
import RoutesEnum from '../../../../types/routes.enum';
import {
  cleanWantWorkOn,
  setActualStep,
  setAreaSelected,
  setCustomFlow
} from '../../redux/ui/ui.slice';

const handleScrollDown = () => {
  window.scrollTo({
    top: document.body.scrollHeight,
    behavior: 'smooth'
  });
};

const UserHubSelection = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [scrollPosition] = useState<{
    [key: string]: number;
  }>({});
  const [activeTrendingId, setActiveTrendingId] = useState<
    number | string | null
  >(null);

  const { trendingAffirmations, selectedTemplateTrending } =
    useAppSelector(affirmationsTemplate);

  const handleTrendingClick = async (_template: TopItemDto, area: string) => {
    setActiveTrendingId(_template.id);
    if (_template.type === 'adminAffirmation') {
      dispatch(setCustomFlow(false));
      await dispatch(getPublishedAffirmationById(_template.id));
      if (selectedTemplateTrending) {
        dispatch(setSelectedTemplate(selectedTemplateTrending));
        dispatch(setAreaSelected({ area: area }));
        dispatch(generateTemplate({ templateId: _template.id }));
        dispatch(cleanWantWorkOn());
      }
    } else if (_template.type === 'outcome') {
      dispatch(setCustomFlow(false));
      dispatch(setAreaSelected({ area: area }));
      dispatch(
        setSelectedOutcome({
          id: _template.id as unknown as number,
          outcome: _template.name,
          recommendation: false
        })
      );
      dispatch(cleanWantWorkOn());
    }

    dispatch(setActualStep(3));
    navigate(RoutesEnum.NEW_AFFIRMATION);
  };
  useEffect(() => {
    dispatch(getTrendingAffirmations());
  }, [dispatch]);

  if (!trendingAffirmations) return null;

  return (
    <Box sx={{ width: '100%' }}>
      <Typography
        variant="h6"
        sx={{
          marginTop: '1.5rem',
          fontFamily: '"Nunito"',
          fontWeight: '700',
          fontSize: '1.5rem',
          color: Colors.black
        }}>
        Trending
      </Typography>
      <Grid container spacing={2} sx={{ marginTop: '0.15rem' }}>
        {trendingAffirmations.topItems.map((trending) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={trending.id}>
            <TrendingButton
              template={trending}
              activeTrendingId={activeTrendingId}
              handleTemplateClick={handleTrendingClick}
              scrollPosition={scrollPosition['trending'] || 0}
              handleScrollDown={handleScrollDown}
              area={'Trending'}
              fullWidth={true}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default UserHubSelection;
